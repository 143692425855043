<template>
  <v-layout
    class="wrapper"
    align-center
    shrink
    @click.prevent="() => $emit('click')"
  >
    <div v-if="active" class="decorator"></div>
    <v-layout class="my-3" shrink>
      <DefaultLabel :class="disabled ? 'disabled' : active ? 'tab-active' : 'tab'">{{
        title
      }}</DefaultLabel>
    </v-layout>
  </v-layout>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
    },
    active: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  components: {
    DefaultLabel: () => import("@/components/text/DefaultLabel"),
    DefaultTitle: () => import("@/components/text/DefaultTitle"),
  },
};
</script>

<style class="scss" scoped>
.tab {
  padding-left: 12px;
  line-height: 1.5rem !important;
}
.tab-active {
  font-weight: 600 !important;
  line-height: 1.5rem !important;
  padding-left: 10px;
}
.decorator {
  height: 40px;
  width: 2px;
  background-color: var(--v-primary-base);
}
.wrapper {
  cursor: pointer;
}
.disabled {
  opacity: 0.5;
  pointer-events: none !important;
}
</style>